export const SEQUENCE = {
    NUMBER: '%NUMBER',
    YYYY: '%YYYY',
    YY: '%YY',
    MM: '%MM',
    M: '%M',
    DD: '%DD',
    D: '%D',
}

export const USER_ACCOUNT_TYPES = {
    ORGANISATION: 'tenant_organisation',
    ACCOUNTANT: 'tenant_accountant',
}

export const USER_ROLES = {
    PRIMARY_ADMIN: 'primary_admin',
    ADMIN: 'admin',
    ACCOUNTANT: 'accountant',
}

export const GUARD = {
    CENTRAL: 'central',
    ACCOUNTANT: 'accountant',
    TENANT: 'tenant',
    SUBSCRIPTION: 'subscription',
    GUEST: 'guest',
    LINK: 'link',
}

export const STORAGE = {
    TENANT_ACCESS_TOKEN: 'tenant_token',
    CENTRAL_ACCESS_TOKEN: 'central_token',
    TENANT_ID: 'tenant',
}

export const ROUTE_ACTION = {
    IMPORT_TRANSACTIONS: 'import-transactions',
}

export const AUTH_SOCIAL = {
    GOOGLE: 'google',
}

export const DATE_HUMAN_FORMAT = 'MMM D, YYYY'
export const DATE_HUMAN_FORMAT_NO_COMMA = 'MMM D YYYY'
export const DATE_LONG_HUMAN_FORMAT = 'MMMM D, YYYY'
export const DATE_TIME_HUMAN_FORMAT = 'MMM D, YYYY [at] HH:mm'
export const DATE_LONG_TIME_HUMAN_FORMAT = 'MMMM D, YYYY [at] HH:mm'
export const DATE_SERVER_FORMAT = 'YYYY-MM-DD'
export const DATE_SHORT_FORMAT = 'DD/MM/YYYY'
export const DATE_LONG_FORMAT = 'DD/MM/YYYY HH:mm:ss'
export const DATE_SERVER_LONG_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const TIME_FORMAT = 'HH:mm'

export const SYSTEM_LANGUAGES = [
    {
        id: 'en',
        locale: 'language.en',
    },
    {
        id: 'de',
        locale: 'language.de',
    },
    {
        id: 'el',
        locale: 'language.el',
    },
]

export const ACCOUNT_TYPES = {
    ONLINE: 'online',
    BANK_FEED: 'bank_feed',
}

export const ITEM_PER_PAGE = 20

export const NUMBER_OF_COWORKERS = [
    'Only me',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10 and more',
]

export const STATUS_COLORS = {
    draft: 'bg-gray-100',
    unpaid: 'bg-warning-100',
    'part-paid': 'bg-success-100',
    paid: 'bg-success-100',
    due: 'bg-warning-100',
    sent: 'bg-gray-100',
    ready: 'bg-success-100',
    published: 'bg-success-100',
    accepted: 'bg-success-100',
    declined: 'bg-danger-100',
    invoiced: 'bg-success-100',
    expired: 'bg-warning-100',
    open: 'bg-blue-50',
    closed: 'bg-success-100',
    repeating: 'bg-warning-100',
}

export const SM_STATUS_BACKGROUND_COLORS = {
    draft: 'bg-gray-100',
    unpaid: 'bg-warning-50',
    'part-paid': 'bg-success-50',
    paid: 'bg-success-50',
    due: 'bg-warning-50',
    sent: 'bg-gray-100',
    ready: 'bg-success-50',
    published: 'bg-success-50',
    accepted: 'bg-success-50',
    declined: 'bg-danger-50',
    invoiced: 'bg-success-50',
    expired: 'bg-warning-50',
    open: 'bg-blue-50',
    closed: 'bg-success-50',
    repeating: 'bg-warning-50',
}

export const SM_STATUS_COLORS = {
    draft: 'text-gray-700',
    unpaid: 'text-warning-700',
    'part-paid': 'text-success-700',
    paid: 'text-success-700',
    due: 'text-warning-700',
    sent: 'text-gray-700',
    ready: 'text-success-700',
    published: 'text-success-700',
    accepted: 'text-success-700',
    declined: 'text-danger-700',
    invoiced: 'text-success-700',
    expired: 'text-warning-700',
    open: 'text-blue-700',
    closed: 'text-success-700',
    repeating: 'text-warning-700',
}

export const STATUS_ICONS = {
    draft: 'line-icons:alerts-&-feedback:alert-circle',
    unpaid: 'line-icons:alerts-&-feedback:alert-circle',
    'part-paid': 'line-icons:general:check-circle',
    paid: 'line-icons:general:check-circle',
    due: 'line-icons:alerts-&-feedback:alert-triangle',
    sent: 'line-icons:alerts-&-feedback:alert-circle',
    ready: 'line-icons:general:check-circle',
    published: 'line-icons:general:check-circle',
    accepted: 'line-icons:general:check-circle',
    declined: 'line-icons:general:close-circle',
    invoiced: 'line-icons:general:check-circle',
}

export const STATUS_VARIANTS = {
    draft: 'gray',
    unpaid: 'warning',
    'part-paid': 'warning',
    paid: 'success',
    due: 'danger',
    sent: 'gray',
    ready: 'success',
    published: 'success',
    accepted: 'accepted',
    declined: 'declined',
    invoiced: 'invoiced',
}

export const CURRENCIES = {
    eur: {
        id: 'eur',
        symbol: '€',
        iso: 'EUR',
    },
}

export const DOCUMENTS = {
    FOLDER: 'folder',
    FILE: 'file',
}

export const THOUSAND_SEPARATOR = ','
export const DECIMAL_SEPARATOR = '.'

export const CONTACT_RELATIONSHIP_TYPES = {
    person: 'person',
    organisation: 'organisation',
}

export const DEPARTMENT_RELATIONSHIP_TYPES = {
    manager: 'manager',
    parent: 'parent',
}

export const AMOUNT_TYPE = {
    GROSS: 'gross',
    NET: 'net',
}

export const ECHO_CHANNELS = {
    GLOBAL: 'global',
}

export const ECHO_EVENTS = {
    PAYMENT_TRANSACTIONS_IMPORTED: '.payment.transactions.imported',
    CUSTOMER_SUBSCRIPTION_CREATED: '.customer.subscription.created',
    DOCUMENT_EXTRACTION_UPLOADED: '.document_extraction.uploaded',
    BILL_UPLOADED: '.bill.uploaded',
    CHAT_NEW: '.chat.new',
    TENANT_CREATED: '.tenant.created',
    CHAT_MESSAGE: '.chat.message',
    EMAIL_MESSAGE: '.chat.email.message',
    CALENDAR_PUSH: '.calendar_event.push',
    ASSISTANT_MESSAGE: '.assistant.message',
    DOC_EXTRACTION_PERMISSION: '.document_extraction.permission.ask',
    PROJECT_CLIENT_TASKS: '.project.client-tasks',
}

export const ECHO_NOTIFICATIONS = {
    CHAT_UN_ANSWER: 'chat.un_answer',
    TENANT_CREATED: 'tenant.created',
}

export const CONTRACT_STATUS_VARIANTS = {
    new: 'gray',
    running: 'success',
    expired: 'danger',
    cancelled: 'danger',
}

export const CONTRACT_STATUS_ICONS = {
    new: 'line-icons:general:check',
    running: 'line-icons:images:flash',
    expired: 'line-icons:images:flash-off',
    cancelled: 'line-icons:general:slash-circle-01',
}

export const CONTRACT_STATUS_TEXTS = {
    new: 'New',
    running: 'Running',
    expired: 'Expired',
    cancelled: 'Cancelled',
}

export const CONTRACT_BACKGROUND_COLORS = {
    new: 'bg-gray-100',
    running: 'bg-success-50',
    expired: 'bg-danger-50',
    cancelled: 'bg-danger-50',
}

export const CONDITION_OPERATOR = {
    CONTAINS: 'contains',
    NOT_CONTAINS: 'not_contains',
    EXACT: 'exact',
    NOT_EQUALS: 'not_equals',
    EQUALS: 'equals',
    GREATER_THAN: 'greater_than',
    LESS_THAN: 'less_than',
}

export const RULE_TYPE = {
    BANK_TEXT: 'bank_text',
    AMOUNT: 'amount',
    DESCRIPTION: 'description',
}

export const MONEY_FLOW = {
    IN: 'in',
    OUT: 'out',
}

export const MATCHING_TYPE = {
    ALL: 'all',
    ANY: 'any',
}

export const TRANSACTION_TYPE = {
    EXPENSE: 'outgoing',
    INCOME: 'incoming',
    TRANSFER: 'transfer',
}

export const BOOKED_TRANSACTION_TYPE = {
    INCOME: 'income',
    OUTCOME: 'outcome',
}

export const MATCHING_TRANSACTION_TYPES = {
    BILL: 'bill',
    EXPENSE: 'expense',
    INVOICE: 'invoice',
    TRANSACTION: 'transaction',
}

export const JOURNAL_TRANSACTION_TYPE = {
    INVOICE: 'Invoice',
    EXPENSE: 'Expense',
    BILL: 'Bills',
    CREDIT_NOTE: 'Credit Note',
    VENDOR_CREDIT: 'Vendor Credit',
}

export const TRANSACTION_ADJUSTMENTS = {
    MINOR: 'minor',
    BANK_FEE: 'bank_fee',
}

export const TRANSACTION_STATUS_TYPE = {
    UNPAID: 'unpaid',
    PAID: 'paid',
}

export const PAYROLL_TYPE = {
    SALARY: 'salary',
    HOURLY: 'hourly',
}

export const CARD_BRANDS = [
    'diners',
    'discover',
    'jcb',
    'mastercard',
    'paypal',
    'unionpay',
    'visa',
]

export const PAYMENT_TERMS = {
    DUE_ON_RECEIPT: 'due_on_receipt',
    NET15: 'net15',
    NET30: 'net30',
    NET45: 'net45',
    END_OF_MONTH: 'end_of_month',
    DUE_NEXT_MONTH: 'due_next_month',
    CUSTOM: 'custom',
}

export const DEFAULT_COUNTRY_ISO = 'CY'

export const USER_TYPES = {
    ACCOUNTANT: 'accountant',
    TENANT: 'tenant',
}

export const TASK_STATUS = {
    TODO: 'todo',
    IN_PROGRESS: 'in_progress',
    IN_REVIEW: 'in_review',
    DONE: 'done',
}

export const PROJECT_VIEW = {
    GRID: 'grid',
    KANBAN: 'kanban',
}

export const PROJECT_RECURRENCE = {
    EVERY_DAY_EXCLUDING_WEEKEND: 'EVERY_DAY_EXCLUDING_WEEKEND',
    EVERY_DAY_INCLUDING_WEEKEND: 'EVERY_DAY_INCLUDING_WEEKEND',
    EVERY_WEEK: 'EVERY_WEEK',
    EVERY_2_WEEK: 'EVERY_2_WEEK',
    EVERY_MONTH: 'EVERY_MONTH',
    EVERY_MONTH_LAST_DAY: 'EVERY_MONTH_LAST_DAY',
    EVERY_3_MONTH: 'EVERY_3_MONTH',
    EVERY_6_MONTH: 'EVERY_6_MONTH',
    EVERY_12_MONTH: 'EVERY_12_MONTH',
}
